import React from "react";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
import botAvatar from "../images/bot.jpg";
import { chatbotSteps } from "../constants/chatbot";

const Chatbot = () => {
  const config = {
    headerTitle: "SG2 AI Assistant",
    placeholder: "What can I help you with?",
    customDelay: "500",
    botAvatar: botAvatar,
    floating: true,
  };

  const theme = {
    background: "#f5f8fb",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#EF6C00",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#11284b",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };

  return (
    <ThemeProvider theme={theme}>
      <ChatBot config={config} steps={chatbotSteps} floating={true} />
    </ThemeProvider>
  );
};

export default Chatbot;
