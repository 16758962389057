export const chatbotSteps = [
  {
    id: "0",
    message: "Welcome to SG2 Tech! How can I assist you today?",
    trigger: "1",
  },
  {
    id: "1",
    options: [
      { value: "services", label: "What services do you offer?", trigger: "2" },
      {
        value: "solutions",
        label: "Tell me about your solutions",
        trigger: "3",
      },
      { value: "contact", label: "How can I contact SG2 Tech?", trigger: "4" },
      {
        value: "approach",
        label: "What is your consulting approach?",
        trigger: "5",
      },
      {
        value: "industries",
        label: "Which industries do you serve?",
        trigger: "6",
      },
    ],
  },
  {
    id: "2",
    message: "SG2 Tech offers a wide range of technology services:",
    trigger: "2a",
  },
  {
    id: "2a",
    message:
      "• Technology Strategy & Advisory\n• Digital Transformation\n• Cloud Migration & Optimization\n• Cybersecurity & Risk Management\n• Data Analytics & AI Solutions\n• IT Infrastructure Consulting",
    trigger: "7",
  },
  {
    id: "3",
    message: "Our key technology solutions include:",
    trigger: "3a",
  },
  {
    id: "3a",
    message:
      "• Enterprise Architecture Design\n• Cloud Native Application Development\n• Managed IT Services\n• Network & Security Solutions\n• Business Intelligence & Analytics\n• Digital Product Engineering",
    trigger: "7",
  },
  {
    id: "4",
    message: "Connect with SG2 Tech:",
    trigger: "4a",
  },
  {
    id: "4a",
    message:
      "• Phone: +1 (202) 922-0402\n• Email: hello@sg2tech.com\n Business Hours: Monday-Friday, 9 AM - 5 PM CST",
    trigger: "7",
  },
  {
    id: "5",
    message: "Our consulting approach is built on:",
    trigger: "5a",
  },
  {
    id: "5a",
    message:
      "1. Comprehensive Technology Assessment\n2. Strategic Planning\n3. Tailored Solution Design\n4. Collaborative Implementation\n5. Continuous Optimization\n6. Ongoing Support & Innovation",
    trigger: "7",
  },
  {
    id: "6",
    message: "We serve diverse industries, including:",
    trigger: "6a",
  },
  {
    id: "6a",
    message:
      "• Healthcare\n• Financial Services\n• Government & Public Sector\n• Education\n• Manufacturing\n• Retail & E-commerce\n• Technology Startups",
    trigger: "7",
  },
  {
    id: "7",
    message: "Is there anything else I can help you with today?",
    trigger: "1",
  },
  {
    id: "8",
    user: true,
    trigger: "9",
  },
  {
    id: "9",
    message: "Thank you for your interest! Our team will get back to you soon.",
    end: true,
  },
];
