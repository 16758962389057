// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import React from "react";
import "@mantine/core/styles.css";
import "./src/styles/index.css";
import { MantineProvider } from "@mantine/core";
import { theme } from "./src/theme";
import Chatbot from "./src/components/Chatbot";

export const wrapPageElement = ({ element }) => {
  return (
    <MantineProvider theme={theme}>
      {element}
      <Chatbot />
    </MantineProvider>
  );
};
